import { createRouter, createWebHistory } from "vue-router";
import Storage from '@/helper/Storage'
import publicRoutes from './public'
import privateRoutes from './private'
import store from '../store'

const routes = publicRoutes.concat(privateRoutes)
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes,
  scrollBehavior() {
    return { top: 0 }
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(route => route.meta.requiresAuth)) {
//     store.dispatch('User/getAuth')
//       .then((user) => {
//         if(user){
//           next()
//         }else{
//           next({ name: 'Login' });
//         }
//       }).catch((error) => {
//         error;
//         // console.log(error)
//         // store.commit('Message/SET_MESSAGE', {
//         //   message: error.message,
//         //   type: 'warning'
//         // })
//         next({ name: 'Login' })
//       })
//   } else if (to.matched.some(route => route.meta.onlyGuest)) {
//     store.dispatch('User/setAuthFromLocal')
//       .then(() => {
//         if (store.getters['User/isLoggedin']) {
//           next({name: 'TopRappers' })
//         } else {
//           next()
//         }
//       })
//   } else {
//     if (store.getters['User/isFirstLoad']) {
//       store.commit('User/SET_FIRST_LOAD_STATUS', false)
//     }
//     next()
//   }
// })


router.beforeEach((to, from, next) => {
  document.querySelector('.desktop-content').scroll({
    top: 0,
    behavior: "smooth",
  });
  store.dispatch('User/getAuth').then(() => {
    validateRoute(to, from, next);
  }).catch(() => {
    validateRoute(to, from, next);
  });
})

function validateRoute(to, from, next) {
  Storage.Set('PREVIOUS_ROUTE', from.name)
  if (to.matched.some(route => route.meta.requiresAuth)) {
    store.dispatch('User/getAuth')
      .then((user) => {
        if (user) {
          if (to.name != 'StripePayment' && user.type == 'artist' && user.subscription_status == 'in_active' && user.subscription_type == 'paid') {
            next({ name: 'StripePayment' });
          } else {
            next()
          }
        } else {
          next({ name: 'Login' });
        }
      }).catch((error) => {
        console.log(error);
        next({ name: 'Login' })
      })
  } else if (to.matched.some(route => route.meta.onlyGuest)) {
    store.dispatch('User/setAuthFromLocal')
      .then(() => {
        if (store.getters['User/isLoggedin']) {
          // next({name: 'Dashboard' })
          next()
        } else {
          next()
        }
      })
  } else {
    if (store.getters['User/isFirstLoad']) {
      store.commit('User/SET_FIRST_LOAD_STATUS', false)
    }

    store.dispatch('User/getAuth')
      .then((user) => {
        if (user) {
          if (user.type == 'artist' && user.subscription_status == 'in_active' && user.subscription_type == 'paid') {
            next({ name: 'StripePayment' });
          } else {
            next()
          }
        } else {
          next()
        }
      }).catch(() => {
        next()
      })
  }
}

export default router
