import Storage from '@/helper/Storage'

const initialState = () => ({
    topUser: [],
    loading: {},
})
const state = initialState()
  
const actions = {}

const getters = {
    topUser (state) { return state.topUser },
    loading (state) { return state.loading },
}

const mutations = {
    RESET (state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
          state[key] = newState[key]
        })
    },
    SET_TOP_USER (state, topUser) {
        Storage.Set('topUser', topUser)
        state.topUser = topUser
    },
    SET_LOADING (state, loading) {
        Storage.Set('loading', loading)
        state.loading = loading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
