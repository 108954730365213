const initialState = () => ({
    mediaUrls: [],
    loading: true,
})
const state = initialState()
  
const actions = {}

const getters = {
    mediaUrls (state) { return state.mediaUrls },
    loading (state) { return state.loading }
}

const mutations = {
    RESET (state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
          state[key] = newState[key]
        })
    },
    SET_TOP_SONGS (state, mediaUrls) {
        state.mediaUrls = mediaUrls
    },
    SET_LOADING (state, loading) {
        state.loading = loading
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
