import Storage from '@/helper/Storage'
// import API from '@/api/http'
// import store from '..'

const initialState = () => ({
  errors: {},
  artists: []
})

const state = initialState()

const actions = {

}

const getters = {
  artists (state) { return state.artists },
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_ARTISTS (state, artists) {
    Storage.Set('artists', artists)
    state.artists = artists
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}