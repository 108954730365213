const privateRoutes = [
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: () => import('../components/Private/ProfilePage/EditProfile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account-info',
    name: 'AccountInfo',
    component: () => import('../components/Private/ProfilePage/AccountInfo'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path:'/rcode-history',
    name:'RcodeHistory',
    component:()=>import('../components/Private/ProfilePage/RcodeHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path:'/generateRcode',
    name:'GenerateRcode',
    component:()=>import('../components/Private/ProfilePage/GenerateRcode'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/voting-history',
    name: 'VotingHistory',
    component: () => import('../components/Private/ProfilePage/VotingHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/purchase-history',
    name: 'PurchaseHistory',
    component: () => import('../components/Private/ProfilePage/PurchaseHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/stripe-payment',
    name: 'StripePayment',
    component: () => import('../components/Shared/StripePayment'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment-history',
    name: 'PaymentHistory',
    component: () => import('../components/Private/ProfilePage/PaymentHistory'),
    meta: {
      requiresAuth: true,
    },
  },

  
]

export default privateRoutes