import Storage from '@/helper/Storage'
// import API from '@/api/http'
// import store from '..'

const initialState = () => ({
  errors: {},
  states: [],
  statesArtists: [],
  topArtists: [],
  week: null,
  artistCount: null,
  currentWeek: 0,
})

const state = initialState()

const actions = {

}

const getters = {
  states(state) { return state.states },
  topArtists(state) { return state.topArtists },
  week(state) { return state.week },
  artistCount(state) { return state.artistCount },
  currentWeek(state) { return state.currentWeek },
}

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  SET_STATES(state, states) {
    Storage.Set('states', states)
    state.states = states
  },
  SET_ARTISTS(state, topArtists) {
    Storage.Set('topArtists', topArtists)
    state.topArtists = topArtists
  },
  SET_WEEK(state, week) {
    Storage.Set('week', week)
    state.week = week
  },
  SET_CURRENT_WEEK(state, currentWeek) {
    state.currentWeek = currentWeek
  },
  SET_ARTISTS_COUNT(state, artistCount) {
    Storage.Set('artistCount', artistCount)
    state.artistCount = artistCount
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}