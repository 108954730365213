import Storage from '@/helper/Storage'

const initialState = () => ({
  errors: {},
  isLoggedin: false,
  user: { played: 0 }, 
})

const state = initialState()

const actions = {
  getFBUser ({ state, commit }) {
    try {
      return new Promise((resolve) => {
        commit('SET_FB_LOGIN_STATUS', Storage.Get('isFBLoggedin', false, 'bool'))
        commit('SET_FB_USER', Storage.Get('FBUser', state.user , 'object'))
        // state.isLoggedin = Storage.Get('isFBLoggedin', false, 'bool')
        // state.user = Storage.Get('FBUser', {}, 'object')
        resolve(state.user)
      })
    } catch (error) {
      console.log(error)
    }
  },
}

const getters = {
  FBuser (state) { return state.user },
  isFBLoggedin (state) { return state.isLoggedin },
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_FB_USER (state, user) {
    Storage.Set('FBUser', user)
    state.user = user
  },
  SET_FB_LOGIN_STATUS (state, flag) {
    Storage.Set('isFBLoggedin', flag)
    state.isLoggedin = flag
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
