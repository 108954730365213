const publicRoutes = [
    {
        path: '/',
        name: 'TopRappers',
        component: () => import('../components/Public/TopRappers'),
    },
    {
        path: '/community',
        name: 'Community',
        component: () => import('../components/Public/Community'),
    },
    {
        path: '/artists',
        name: 'Artists',
        component: () => import('../components/Public/Artistes'),
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('../components/Public/Signup'),
        meta: {
            onlyGuest: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../components/Public/Login'),
        meta: {
            onlyGuest: true
        }
    },
    {
        path: '/verify-account/:token',
        name: 'VerifyAccount',
        component: () => import('../components/Public/VerifyAccount'),
        meta: {
            onlyGuest: true
        }
    },
    {
        path: '/c/:slug',
        name: 'CProfileWithSlug',
        component: () => import('../components/Private/ProfilePage/CProfile'),
    },
    {
        path: '/rapland-info',
        name: 'RapLandBasics',
        component: () => import('../components/Public/RapLandBasics'),
    },
    {
        path: '/free-university',
        name: 'FreeUniversity',
        component: () => import('../components/Public/FreeUniversity'),
    },
    {
        path: '/top-songs',
        name: 'TopSongs',
        component: () => import('../components/Public/TopSongs'),
    },
    {
        path: '/vernon-davis',
        name: 'VernonDavis',
        component: () => import('../components/Public/VernonDavis'),
    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: () => import('../components/Public/ForgotPassword'),
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import('../components/Public/ResetPassword'),
    },
    {
        path: '/search/:string',
        name: 'Search',
        component: () => import('../components/Public/Search'),
    },
    {
        path: '/:slug/rankings',
        name: 'TopRappersWithState',
        component: () => import('../components/Public/TopRappersState'),
    },
    {
        path: '/:slug/comment',
        name: 'ArtistSubmitComment',
        component: () => import('../components/Private/ArtistSubmitComment'),
    },
    {
        path: '/:slug',
        name: 'ProfileWithSlug',
        component: () => import('../components/Private/ProfilePage/Profile'),
    },
]

export default publicRoutes