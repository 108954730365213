

const initialState = () => ({
  errors: {},
  
  avlBalance:0,
  history:[],
 
  
})

const state = initialState()

const actions = {

}

const getters = {
  avlBalance(state){return state.avlBalance},
  history(state){return state.history},
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },

  SET_AVLBALANCE (state, balance) {
    state.avlBalance = balance
  },

  SET_HISTORY(state,history){
    state.history=history
  },



}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}