import Storage from '@/helper/Storage'
// import API from '@/api/http'
// import store from '..'

const initialState = () => ({
  errors: {},
  states: [],
  statesArtists: [],
  topArtists: [],
  week: {},
  artistCount: null,
  cssLoader: {},
  
})

const state = initialState()

const actions = {

}

const getters = {
  states (state) { return state.states },
  topArtists (state) { return state.topArtists },
  week (state) { return state.week },
  artistCount (state) { return state.artistCount },
  cssLoader (state) { return state.cssLoader },
}

const mutations = {
  RESET (state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_STATES (state, response) {
    Storage.Set('states', response.top_artists)
    state.states[response.top_artists[0].slug] = response.top_artists
  },
  SET_ARTISTS (state, response) {
    Storage.Set('topArtists', response.top_artists[0].user)
    state.topArtists[response.top_artists[0].slug] = response.top_artists[0].user
  },
  SET_WEEK (state, response) {
    Storage.Set('week', response.current_year_weeks)
    state.week[response.top_artists[0].slug] = response.current_year_weeks
  },
  SET_ARTISTS_COUNT (state, artistCount) {
    Storage.Set('artistCount', artistCount)
    state.artistCount = artistCount
  },
  SET_CSS_LOADER (state, data) {
    Storage.Set('cssLoader', data.status)
    state.cssLoader[data.slug] = data.status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}