import Storage from '@/helper/Storage'

const initialState = () => ({
    currentUser: {
        // plays: null,
        // votes: null,
        isTopArtist: null,
        // trophy: null,
    },
    loading: {},
    isVoted: {},
    mediaUrls: [],
})

const state = initialState()

const actions = {

}

const getters = {
    currentUser(state) { return state.currentUser },
    isVoted(state) { return state.isVoted },
    loading(state) { return state.loading },
    mediaUrls(state) { return state.mediaUrls },
}

const mutations = {
    RESET(state) {
        const newState = initialState()
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        })
    },
    SET_ARTIST(state, response) {
        Storage.Set('currentUser', response)
        Storage.Set('isVoted', response.is_voted)
        // state.currentUser[response.artist.slug] = response
        state.currentUser[response.artist.slug] = response.artist
        state.currentUser[response.artist.slug].plays = response.plays
        state.currentUser[response.artist.slug].votes = response.votes
        state.currentUser[response.artist.slug].isTopArtist = response.is_top_artist
        state.currentUser[response.artist.slug].trophy = response.trophy
        state.isVoted[response.artist.slug] = response.is_voted
        
    },
    SET_ISVOTED(state, data) {
        Storage.Set('isVoted', data.isVoted)
        state.isVoted[data.slug] = data.isVoted
        state.currentUser[data.slug].votes = data.votes
    },
    SET_LOADING(state, data) {
        Storage.Set('loading', data.status)
        state.loading[data.slug] = data.status
    },
    SET_MEDIA(state, data) {
        Storage.Set('mediaUrls', data.mediaUrls)
        state.mediaUrls[data.slug] = data.mediaUrls
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}