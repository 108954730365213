<template>
  <div id="app" :class="'lg:bg-gray-500'">
    <img src="./assets/image/default.png" id="defaultImage" class="hidden" />
    <!-- <Login-From /> -->
    <div class="">
      <div class="grid grid-cols-2 lg:grid hidden">
        <div class="text-center bleez-text font-bold py-4">
          <p>Who is the top rapper in your state?</p>
        </div>
        <div class="text-center text-white py-4">
          <p>New rankings released Monday at 12:00 EST!</p>
        </div>
      </div>
      <!-- <hr class="lg:border-gray-200 lg:mb-8 lg:border-2 md:m-0"> -->
      <div class="z-10 desktop-size w-full  lg:my-4 m-0">
        <div class="desktop-border  bg-white  z-0 w-full p-0 relative">
          <div :class="' lg:z-0 w-auto desktop-content ' + pageColorClass + ' ' + inputColorClass + ' ' + overFlowClass">
            <!-- <transition name="fade" mode="out-in">
                <router-view></router-view>
              </transition> -->
            <Header @menuToggle="menuToggle"/>
            <!-- <router-view v-slot="{ Component }">
                <transition>
                  <component :is="Component" />
                </transition>
              </router-view> -->

            <router-view  />
          </div>
        </div>
      </div>
      <div class="text-center bleez-text font-bold lg:block hidden mt-8">
        <p>Instagram: <a :href="'https://www.instagram.com/rapland.charts'">@rapland.charts</a> - Twitter: <a
            :href="'https://twitter.com/raplandcharts'">@raplandcharts</a></p>
      </div>
    </div>
  </div>
</template>

<script>

import Header from "./components/Shared/Header";

export default {
  name: 'App',
  components: {
    Header
  },
  watch: {
    '$route'() {
      var html = document.getElementsByTagName("html");
      if (this.pageColorClass && !this.greenClassAdded) {
        html[0].classList.add("rapbg");
      } else {
        html[0].classList.remove("rapbg");
      }
      // element[0].classList.toggle("mystyle");
    }
  },
  computed: {
    currentRouteName() {
      return this.$route;
    },
    pageColorClass() {
      return !this.greenPages.includes(this.currentRouteName.name) ? '' : 'rapbg';
    },
    inputColorClass() {
      var greenPages = ['Signup', 'Login', 'VerifyAccount', 'ForgotPassword', 'ResetPassword', 'EditProfile']
      return greenPages.includes(this.currentRouteName.name) ? 'green-input' : '';
    },
    overFlowClass() {
      return this.menuToggleType == false ? '  lg:overflow-y-scroll' : '';
    },
  },
  data() {
    return {
      show: true,
      open: false,
      greenPages: ['TopRappers', 'Community', 'Artists', 'Signup', 'Login', 'VerifyAccount', 'ForgotPassword', 'ResetPassword', 'Search', 'TopRappersWithState', 'EditProfile', 'AccountInfo', 'VotingHistory', 'PurchaseHistory', 'TopRappersState','RcodeHistory'],
      greenClassAdded: false,
      menuToggleType: false,
    }
  },
  methods: {
    menuToggle(type){
      this.menuToggleType = type;
   }
  },

}
</script>