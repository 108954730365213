import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import './assets/sass/index.scss';
import Toast from "vue-toastification";

const app = createApp(App)
app.use(router)
app.use(store)
app.use(Toast)
app.mount('#app')
