import Storage from '@/helper/Storage'
import API from '@/api/http'
import store from '../../store'

const initialState = () => ({
  errors: {},
  isLoggedin: false,
  isFirstLoad: true,
  user: {},
  user_limits: {},
  login_success_route: process.env.VUE_APP_LOGIN_SUCCESS_ROUTE,
  signupStep: false,
})

const state = initialState()

const actions = {
  getAuth({ state, commit }) {
    try {
      let rejectError = new Error('Please login to access.')
      return new Promise((resolve, reject) => {
        let isLoggedin = Storage.Get('isLoggedin', false, 'bool')
        state.isLoggedin = isLoggedin
        if (state.isFirstLoad) {
          commit('SET_FIRST_LOAD_STATUS', false)
          if (!isLoggedin) {
            reject(rejectError)
          } else {
            state.user = Storage.Get('user', state.user, 'object')
            API.get('profile', state.user.token)
              .then((response) => {
                // if(response.user.role == 'admin' || response.user.role == 'sub_admin'){
                response.user['artist_songs_count'] = response.artist_songs_count;
                response.user['token'] = response.token;
                response.user['profile_songs'] = response.profile_songs;
                API.setUser(response.user);
                // }
                if (response.limits) {
                  store.commit('User/SET_ACCOUNT_LIMITS', response.limits)
                }
                resolve(response.user)
              }).catch((error) => {
                error
                reject(rejectError)
              })
          }
        } else if (isLoggedin) {
          state.user = Storage.Get('user', state.user, 'object')
          resolve(state.user)
        } else {
          reject(rejectError)
        }
      })
    } catch (error) {
      console.log(error)
    }
  },
  setAuthFromLocal({ state }) {
    return new Promise((resolve) => {
      if (state.isFirstLoad) {
        // commit('SET_FIRST_LOAD_STATUS', false)
        state.user = Storage.Get('user', state.user, 'object')
        state.isLoggedin = Storage.Get('isLoggedin', state.isLoggedin, 'bool')
        resolve()
      } else {
        resolve()
      }
    })
  },
}

const getters = {
  user(state) { return state.user },
  user_limits(state) { return state.user_limits },
  isLoggedin(state) { return state.isLoggedin },
  isFirstLoad(state) { return state.isFirstLoad }
}

const mutations = {
  RESET(state) {
    const newState = initialState()
    Object.keys(newState).forEach(key => {
      state[key] = newState[key]
    })
  },
  SET_USER(state, user) {
    Storage.Set('user', user)
    state.user = user
  },
  SET_ACCOUNT_LIMITS(state, limits) {
    Storage.Set('user_limits', limits)
    state.user_limits = limits
  },
  SET_LOGIN_STATUS(state, flag) {
    Storage.Set('isLoggedin', flag)
    state.isLoggedin = flag
  },
  SET_FIRST_LOAD_STATUS(state, flag) {
    // Storage.Set('isFirstLoad', flag)
    state.isFirstLoad = flag
  },
  SET_LOGIN_SUCCESS_ROUTE(state, route) {
    state.login_success_route = route
  },
  SET_SIGNUP_STEP(state, back) {
    state.signupStep = back
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}